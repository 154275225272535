<template>
    <div>
        <b-container>
            <b-row align-v="center" align-h="between">
                <b-col cols="6">
                    Date Range:
                <date-picker v-model="range" is-range @input="rangeChange">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div>
                            <input
                                :value="inputValue.start"
                                v-on="inputEvents.start"
                                class="w-auto"
                            />
                            <b-icon-arrow-right></b-icon-arrow-right>
                            <input
                                :value="inputValue.end"
                                v-on="inputEvents.end"
                                class="w-auto"
                            />
                        </div>
                    </template>
                </date-picker>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label="Results"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="table-select-results"
                            v-model="resultNum"
                            :options="resultOptions"
                            @change="resultChange"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label="Filter"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
        <p></p>
        <b-table
            striped
            bordered
            small
            hover
            sticky-header="true"
            head-variant="light"
            responsive="sm"
            :filter="filter"
            :filter-included-fields="filterOn"
            :items="items"
            :fields="fields"
        >
        </b-table>
    </div>
</template>

<script>
export default {
    name: "DeviceLogs",
    data() {
        return {
            fields: [
                {key: 'time', label: 'Date', formatter: this.dateFieldFormat},
                {key: 'code', label: 'Code', formatter: this.codeFormat},
                {key: 'type', label: 'Type', formatter: this.typeFormat},
                {key: 'msg', label: 'Message'}
            ],
            codeOptions: [
                {value: 0, text: 'None'},
                {value: 1, text: 'Restart'},
                {value: 2, text: 'WiFi'},
                {value: 3, text: 'FS'},
                {value: 4, text: 'REST'},
                {value: 5, text: 'Sensor'},
                {value: 6, text: 'Schedule'},
                {value: 7, text: 'RTC'},
            ],
            typeOptions: [
                {value: 0, text: 'General'},
                {value: 1, text: 'Info'},
                {value: 2, text: 'Warning'},
                {value: 3, text: 'Error'},
                {value: 4, text: 'Debug'}
            ],
            resultOptions: [
                100, 250, 500
            ],
            filter: null,
            resultNum: 100,
            filterOn: ['msg'],
            range: {
                start: new Date(),
                end: new Date()
            }
        }
    },
    created() {
        this.range.start.setDate(this.range.start.getDate() - 30);
        this.$store.dispatch('getDeviceLogs', {
            id: this.$store.getters.adminSelectedDev.deviceId,
            userId: this.$store.getters.adminSelectedDev.userId,
            results: this.resultNum
        }).then(() => {

        }).catch(() => {

        });
    },
    methods: {
        dateFieldFormat(value) {
            //console.log(value);
            return this.moment(value).format('MM/DD/YYYY h:mm:ss A');

        },
        resultChange() {
            let startDate = this.range.start;
            let endDate = this.range.end;
            startDate.setHours(0,0,0,0);
            endDate.setHours(0,0,0,0);
            endDate.setDate(endDate.getDate()+1);
            this.$store.dispatch('getDeviceLogs', {
                id: this.$store.getters.adminSelectedDev.deviceId,
                userId: this.$store.getters.adminSelectedDev.userId,
                results: this.resultNum,
                sd: Math.round(startDate.getTime() / 1000),
                ed: Math.round(endDate.getTime() / 1000)
            }).then(() => {

            }).catch(() => {

            });
        },
        codeFormat(value) {
            return this.codeOptions[value].text;
        },
        typeFormat(value) {
            return this.typeOptions[value].text;
        },
        rangeChange() {
            let startDate = this.range.start;
            let endDate = this.range.end;
            startDate.setHours(0,0,0,0);
            endDate.setHours(0,0,0,0);
            endDate.setDate(endDate.getDate()+1);
            this.$store.dispatch('getDeviceLogs', {
                id: this.$store.getters.adminSelectedDev.deviceId,
                userId: this.$store.getters.adminSelectedDev.userId,
                results: this.resultNum,
                sd: Math.round(startDate.getTime() / 1000),
                ed: Math.round(endDate.getTime() / 1000)
            }).then(() => {

            }).catch(() => {

            });
        }
    },
    computed: {
        items() {
            return this.$store.getters.deviceLogs;
        }
    }
}
</script>

<style scoped>

</style>
