<template>
    <div>
        <h3>Device Debug</h3>
        <hr>
        <b-button-toolbar>
            <b-button-group class="mx-1">
                <b-dropdown text="Commands">
                    <b-dropdown-item @click="startSpray">{{sprayTxt}}</b-dropdown-item>
                    <b-dropdown-item @click="startTest">Start 5 Min Test</b-dropdown-item>
                    <b-dropdown-item @click="stopSpray">Stop Spray</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="pingDevice">Ping Device</b-dropdown-item>
                    <b-dropdown-item @click="statusUpdate">Status Update</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="fwCheck">Force FW Upgrade Check</b-dropdown-item>
                    <b-dropdown-item @click="syncAdmin">Force Admin User Sync</b-dropdown-item>
                    <b-dropdown-item @click="syncDevSch">Device Schedule Sync to Server</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="wdCheck">Force WD FW Upgrade Check</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="rebootDev">Reboot Device</b-dropdown-item>
                    <b-dropdown-item @click="wipeInt">Wipe Internal Storage</b-dropdown-item>
                    <b-dropdown-item @click="newApiUrl">Change API URL</b-dropdown-item>
                </b-dropdown>
            </b-button-group>
            <admin-device-menu></admin-device-menu>
        </b-button-toolbar>
        <hr>
        <div class="text-center">
            <b-container>
                <b-row>
                    <b-col>
                        Device ID: {{$route.params.id}}
                        <div>
                            <b-badge v-if="isDevOnline" variant="success">Online</b-badge>
                            <b-badge v-else variant="danger">Offline</b-badge>
                        </div>
                    </b-col>
                    <b-col>
                        Located At: {{geoLoc}}
                    </b-col>
                </b-row>
            </b-container>
            <div>
                Last Command: {{lastCommand}}
            </div>
        </div>
        <hr>
        <b-container class="text-center">
            <b-row cols="2" align-v="center">
                <b-col>
                    <b-button @click="refreshData" variant="primary">Refresh</b-button>
                </b-col>
                <b-col>
                    <b-checkbox @change="autoRefreshChange" v-model="checkRefresh">Auto Refresh</b-checkbox>
                </b-col>
            </b-row>
        </b-container>
        <hr>
        <h4 class="text-center">Info</h4>
        <hr>
        <device-info></device-info>
        <p></p>
        <h4 class="text-center">Logs</h4>
        <hr>
        <device-logs></device-logs>
        <b-modal ref="confirm-modal" hide-footer centered title="Confirmation">
            <div class="d-block text-center">
                <h5>Admin PIN Required</h5>
            </div>
            <hr>
            <b-form-group>
                <label class="m-25" for="change-pin">PIN:</label>
                <b-form-input
                    class="m-75"
                    id="change-pin"
                    type="number"
                    :formatter="pinFormatter"
                    min="111111"
                    max="999999"
                    v-model="form.pin"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group v-if="adminConfirmMode==='url'">
                <label class="m-25" for="change-url">URL:</label>
                <b-form-input
                    class="m-75"
                    id="change-url"
                    v-model="form.url"
                    required
                ></b-form-input>
            </b-form-group>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="danger" block @click="changeYes">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="primary" block @click="changeNo">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import DeviceInfo from "@/components/AdminModules/Devices/DeviceInfo";
import DeviceLogs from "@/components/AdminModules/Devices/DeviceLogs";
import AdminDeviceMenu from "@/components/AdminDeviceMenu";

export default {
    name: "AdminDeviceDebug",
    props: ['id'],
    components: {
        DeviceInfo,
        DeviceLogs,
        AdminDeviceMenu
    },
    data() {
        return {
            dev: null,
            devType: '',
            checkRefresh: false,
            refreshTimer: null,
            pingInterval: null,
            form: {
                pin: null,
                url: null
            },
            adminConfirmMode: null
        }
    },
    created() {
        this.$store.commit('storeLastSocketCmd', '');
        this.pingInterval = setInterval(() => {
            if (!this.$store.getters.isDeviceOnline) {
                this.$store.dispatch('sendSockMessage', {text: 'ping'});
            }
            else {
                let curTime = Date.now();
                let lastPing = this.$store.getters.getLastDevicePingTime;
                let elapsedTime = Math.round(Math.abs((curTime - lastPing) / 1000));
                if (elapsedTime > 59) {
                    this.$store.dispatch('sendSockMessage', {text: 'ping'});
                }
                if (elapsedTime > 69) {
                    this.$store.commit('setDeviceOnlineStatus', false);
                }
            }
        }, 10000);
        this.dev = this.$store.getters.adminSelectedDev;
        this.devType = this.dev.deviceType;
        this.$store.dispatch('getAdminDeviceGeo', {id: this.dev.deviceId, userId: this.dev.userId});
        this.$store.dispatch('joinDeviceRoom', this.dev.deviceId);
    },
    mounted() {

    },
    methods: {
        refreshData() {
            this.$store.dispatch('getOtherDevInfo', {id: this.dev.deviceId, userId: this.dev.userId}).then(() => {
                this.$store.commit('storeDeviceInfo', this.$store.getters.otherDevInfo);
                this.$store.dispatch('getCurDevFwVer',
                    {
                        id: this.$store.getters.adminSelectedDev.deviceId,
                        userId: this.$store.getters.adminSelectedDev.userId
                    }
                );
            }).catch(() => {

            });
            this.$store.dispatch('getDeviceLogs', {id: this.dev.deviceId, userId: this.dev.userId});
        },
        pinFormatter(value) {
            return value.substr(0, 6);
        },
        autoRefreshChange() {
            if (this.checkRefresh) {
                this.refreshTimer = setInterval(() => {
                    this.refreshData();
                }, 10000);
            }
            else {
                if (this.refreshTimer) {
                    clearInterval(this.refreshTimer);
                }
            }
        },
        pingDevice() {
            this.$store.dispatch('sendSockMessage', {text: 'ping'});
        },
        statusUpdate() {
            this.$store.dispatch('sendSockMessage', {text: 'qStat'});
        },
        rebootDev() {
            this.adminConfirmMode = "reboot";
            this.form.pin = '';
            this.form.url = '';
            this.$refs['confirm-modal'].show();
        },
        wipeInt() {
            this.adminConfirmMode = "wipe";
            this.form.pin = '';
            this.form.url = '';
            this.$refs['confirm-modal'].show();
        },
        newApiUrl() {
            this.adminConfirmMode = "url";
            this.form.pin = '';
            this.form.url = '';
            this.$refs['confirm-modal'].show();
        },
        changeYes() {
            if (this.adminConfirmMode !== null) {
                if (this.adminConfirmMode === "wipe") {
                    this.$store.dispatch('sendSockMessage', {
                        text: "cleanInt",
                        pin: this.form.pin
                    });
                    this.$refs['confirm-modal'].hide();
                    this.adminConfirmMode = null;
                }
                else if (this.adminConfirmMode === "url") {
                    this.$store.dispatch('sendSockMessage', {
                        text: "restSrvUp",
                        pin: this.form.pin,
                        url: this.form.url
                    });
                    this.$refs['confirm-modal'].hide();
                    this.adminConfirmMode = null;
                }
                else if (this.adminConfirmMode === "reboot") {
                    this.$store.dispatch('sendSockMessage', {
                        text: "resetEsp",
                        pin: this.form.pin
                    });
                    this.$refs['confirm-modal'].hide();
                    this.adminConfirmMode = null;
                }
            }
        },
        changeNo() {
            this.$refs['confirm-modal'].hide();
        },
        syncDevSch() {
            this.$store.dispatch('sendSockMessage', {
                text: "devSchSync"
            });
        },
        startSpray() {
            if (this.devType === "InsectControl") {
                this.$store.dispatch('sendSockMessage', {
                    text: "runSpray",
                    user: this.$store.getters.user,
                    dur: 30,
                    zone: 1
                });
            }
            else if (this.devType === "CoolMist") {
                this.$store.dispatch('sendSockMessage', {
                    text: "runSpray",
                    user: this.$store.getters.user,
                    dur: 1,
                    mode: 4,
                    zone: 0,
                    onTime: 30,
                    offTime: 30
                });
            }
        },
        startTest() {
            this.$store.dispatch('sendSockMessage', {
                text: "runTest",
                user: this.$store.getters.user,
                dur: 300,
                zone: 1
            });
        },
        stopSpray() {
            this.$store.dispatch('sendSockMessage', {
                text: "stopSpray"
            });
        },
        fwCheck() {
            this.$store.dispatch('sendSockMessage', {
                text: "checkFW"
            });
        },
        wdCheck() {
            this.$store.dispatch('sendSockMessage', {
                text: "checkWDFW"
            });
        },
        syncAdmin() {
            this.$store.dispatch('sendSockMessage', {
                text: "adminSync"
            });
        }
    },
    beforeDestroy() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }
        this.$store.dispatch('leaveDeviceRoom', this.dev.deviceId);
        this.$store.commit('setDeviceOnlineStatus', false);
        this.$store.commit('setActiveDevice', '9x9x9x');
        this.$store.commit('storeLastSocketCmd', '');
    },
    computed: {
        geoLoc() {
            if (this.$store.getters.adminSelectedGeo !== null) {
                return this.$store.getters.adminSelectedGeo.street;
            }
            return '';
        },
        sprayTxt () {
          if (this.devType === "InsectControl")
              return "Start 30 Sec Spray";
          else if (this.devType === "CoolMist")
              return "Start 1 Min Spray";
          else
              return "Start 30 Sec Spray";
        },
        isDevOnline() {
            return this.$store.getters.isDeviceOnline;
        },
        lastCommand() {
            return this.$store.getters.lastSocketCommand;
        }
    }
}
</script>

<style scoped>

</style>
