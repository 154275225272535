<template>
    <div>
        <b-table
            striped
            bordered
            small
            hover
            head-variant="light"
            responsive="sm"
            :items="items"
            :fields="fields"
        >
        </b-table>
    </div>
</template>

<script>
export default {
    name: "DeviceInfo",
    props: ['id'],
    data() {
        return {
            fields: [
                {key: 'mode', label: 'System Mode', formatter: this.modeFormat},
                {key: 'tank', label: 'Tank Level (Gal.)', formatter: this.tankFormat},
                {key: 'boot', label: 'System Up Time (Hours)', formatter: this.calcUpTime},
                {key: 'lastPing', label: 'Last Ping (Secs)', formatter: this.lastPingFormat},
                {key: 'sdSize', label: 'SD Size (MB)'},
                {key: 'sdFree', label: 'SD Free (MB)'},
                {key: 'ffSize', label: 'FFat Size (KB)'},
                {key: 'ffFree', label: 'FFat Free (KB)'},
                {key: 'intTemp', label: 'Int Temp', formatter: this.intTempFormat},
                {key: 'wifiSig', label: 'WiFi Strength (dBm)'},
                {key: 'fwVer', label: 'FW Ver.'},
                {key: 'picFW', label: 'WD FW Ver.', formatter: this.picFwFormat},
            ],
            modeOptions: [
                {value: 0, text: 'Auto'},
                {value: 1, text: 'Local'},
                {value: 2, text: 'Off'}
            ],
            devUpTime: 0
        }
    },
    created() {
        this.$store.dispatch('getOtherDevInfo', {id: this.$store.getters.adminSelectedDev.deviceId, userId: this.$store.getters.adminSelectedDev.userId}).then(() => {
            this.$store.commit('storeDeviceInfo', this.$store.getters.otherDevInfo);
            this.$store.dispatch('getCurDevFwVer',
                {
                    id: this.$store.getters.adminSelectedDev.deviceId,
                    userId: this.$store.getters.adminSelectedDev.userId
                }
            );
        }).catch(() => {

        });

    },
    methods: {
        modeFormat(value) {
            if (isNaN(value)) {
                return this.modeOptions[2].text;
            }
            else {
                return this.modeOptions[value].text;
            }
        },
        lastPingFormat(value) {
            if (value !== null)
                return value.toFixed(2);
            else
                return 0;
        },
        intTempFormat(value) {
          if (value === 0)
            return "---";
          else
              return value + " F";
        },
        tankFormat(value) {
            if (this.$store.getters.adminSelectedDev.deviceType === "InsectControl") {
                if (value !== null)
                    return value.toFixed(2);
                else
                    return 0;
            }
            else if (this.$store.getters.adminSelectedDev.deviceType === "CoolMist")
                return "---";
            else
                return "---";
        },
        picFwFormat(value) {
          if (value === -1) {
              return "Unknown";
          }
          else if (value === 255) {
              return "Err";
          }
          else {
              return value;
          }
        },
        calcUpTime(value) {
            let curTime = new Date();
            let bootTime = new Date(value * 1000);
            let hours = (((curTime - bootTime) / 1000) / 60) / 60;
            return hours.toFixed(2);
        }
    },
    computed: {
        items() {
            let info = [];
            if (this.$store.getters.otherDevInfo !== null) {
                let infoList = this.$store.getters.otherDevInfo;
                if (this.$store.getters.curDevFwVer !== null)
                    infoList.fwVer = this.$store.getters.curDevFwVer.fwCurVerStr;
                else
                    infoList.fwVer = "-.-.-";
                info.push(infoList);
            }
            else {
                info.push({
                    tank: 0.0,
                    boot: Date.now() / 1000,
                    mode: 2,
                    sdSize: 0,
                    sdFree: 0,
                    ffSize: 0,
                    ffFree: 0,
                    wifiSig: 0,
                    lastUpdate: 0,
                    fwVer: "-.-.-"
                });
            }
            return info;
        }
    }
}
</script>

<style scoped>

</style>
